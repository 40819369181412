
function App() {
  return (
    <h1 className="text-3xl font-bold underline text-center">
      GuapaStudio!
    </h1>
  );
}

export default App;
